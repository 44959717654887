<template>
  <div class="container mt-4">
    <div class="row ">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
    <div class="row">
      <table class="table table-sm m-0 headertable"   style="border-bottom-color: white !important;">
        <tr class="border-bottom ">
      <td class="text-center" colspan="4">
        فاتورة ضريبية
      </td>
    </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">{{ invoice.created_at | moment("DD/MM/YYYY") }}</td>
          <td class="text-right">تاريخ اصدار الفاتورة</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">{{ invoice.invoice_id }}</td>
          <td class="text-right">رقم الفاتورة التسلسلي </td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">شركة أبناء عبد الرحمن علي البصيلي</td>
          <td class="text-right">اسم المورد</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">1000039</td>
          <td class="text-right">رقم المورد</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"><u>الرياض - حي الملـز</u></td>
          <td class="text-right">عنوان المورد</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">300047805100003</td>
          <td class="text-right">رقم الضريبي للمورد</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">الشركـــة الســـعوديــة للكهربــاء</td>
          <td class="text-right">اسم العميل</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right">مــديــنــة الرياض - حي العارض</td>
          <td class="text-right">عنوان العميل</td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="float-left">
              <vue-qrcode :value="invoice.qrcode" :options="{ width: 150 }"></vue-qrcode>
            </span>
          </td>
          <td class="text-right">300000361310003</td>
          <td class="text-right">رقم الضريبي للعميل</td>
        </tr>
      </table>
    </div>
    <div class="row">
      <table class="table table-sm m-0 ">
        <tr>
          <td style="border-top-color: white !important;"></td>
          <td class="text-center " style="width: 20rem;"><b>{{ invoice_type }}</b></td>
          <td style="border-top-color: white !important; border-right-color: white !important;"></td>
          <td style="border-top-color: white !important;"></td>
        </tr>
      </table>
      <table class="table table-sm m-0">
        <tr>
          <td colspan="4" class="text-center"> <b>رقــــم العــــــــــــــــــــقـــد </b></td>
          <td colspan="4" class="text-center"> <b> رقـــــم الــــــــــــــمستخلص </b></td>
        </tr>
        <tr>
          <td colspan="4" class="text-center">{{ invoice.contract_no }}</td>
          <td colspan="4" class="text-center">{{ invoice.po }}</td>
        </tr>
        <tr>
          <th class="text-center">إجمالي المبلغ شامل الضريبة (ريال سعودي)</th>
          <th class="text-center"> مبلغ الضريبة </th>
          <th class="text-center">نسبة الضريبة</th>
          <th class="text-center">اجمالي المبلغ قبل اي خصومات او غرامات</th>
          <th class="text-center" style="width: 15rem;">رقــم أمر العمل</th>
          <th class="text-center">تاريخ التوريد الفعلي</th>
          <th class="text-center" style="width: 25rem;">وصف العمل</th>
          <th class="text-center">م</th>
        </tr>
        <tr v-for="(value, index) in items" :key="index">
          <td class="text-center">
            {{ sum(value.amount) }}
          </td>
          <td class="text-center">
            {{ comma(value.amount * 0.15) }}
          </td>
          <td class="text-center">
            15%
          </td>
          <td class="text-center">
            {{ comma(value.amount) }}
          </td>
          <td class="text-center" style="width: 15rem;">
            {{ value.unit }}
          </td>
          <td class="text-center">
            {{ value.dategeo }}
          </td>
          <td class="text-center"  style="width: 25rem;">
            {{ value.item }}
          </td>
          <td class="text-center">{{ increment_index(index) }}</td>
        </tr>
        <tr>
          <td class="text-center">{{ invoice_gross_amount }}</td>
          <td class="text-center">{{ invoice_vat_amount }}</td>
          <td class="text-center">15%</td>
          <td class="text-center">{{ invoice_total_amount }}</td>
          <td colspan="4" class="text-right">المجموع بــالريـال الـســعودي</td>
        </tr>
        <tr>
          <td class="text-center">{{ invoice_fine_amount }}</td>
          <td colspan="7" class="text-right">الغــرامــات</td>
        </tr>
        <tr>
          <td class="text-center"> {{ invoice_net_amount }}</td>
          <td colspan="7" class="text-right"> (ريـال ســعودي) المجموع مع الضريبة</td>
        </tr>

        <tr>
          <td colspan="8" class="text-right">
            القيمة بالحروف :{{ invoice.amount_words_ar }}
          </td>
        </tr>
        <tr>
          <td colspan="8" class="text-left">
            <div style="margin-left: 1em; height:100px;  width:200px; border-bottom:solid 1px black;">
            </div>
            <div style="width:200px;">
              <h2 class="text-right">مـــديــر النقد</h2>
              <h2 class="text-right">أ / يعقوب فضل الله</h2>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>
  
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoice",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      invoice_rentention_amount: 0,
      invoice_total_amount_us: 0,
      invoice_vat_amount_us: 0,
      invoice_gross_amount_us: 0,

      hc_address: "",
      invoice_type: "",
      invoice_fine_amount: 0,
      invoice_net_amount:0,
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    trigger(t) {
      alert(t);
    },
    toggle() {
      this.show = !this.show;
    },
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    // comma(data) {
    //   return (Math.round((Number(data) + Number.EPSILON) * 100) / 100).toFixed(2).toLocaleString("en", {
    //     minimumFractionDigits: 2,
    //   });

    // },
    sum(n1) {
      let vat = Number(n1) * 0.15;
      let total = Number(n1) + vat;
      return this.comma(total);
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];
          // let total_amount = 0;
          let total_amount_us = 0;

          this.items.forEach((x) => {
            total_amount_us += Number(x.absent);
          });

          let vat_invoice_us = total_amount_us * 0.15;
          let gross_total_us = total_amount_us + vat_invoice_us;


          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount)

          this.invoice_total_amount_us = this.comma(total_amount_us);
          this.invoice_vat_amount_us = this.comma(vat_invoice_us);
          this.invoice_gross_amount_us = this.comma(gross_total_us);
          this.invoice_fine_amount = this.comma(this.invoice.fine_amount)

          if (this.invoice.invoice_type === "Progress Service Order") {
            this.invoice_type = " دفعه اولى - جزئيه"
          } else if (this.invoice.invoice_type === "Partial Payment") {
            this.invoice_type = " دفعه ثانية - جزئيه"
          } else if (this.invoice.invoice_type === "Final Payment") {
            this.invoice_type = "دفعه نهائية"
          }

        })
        .catch((e) => {
          console.log(e);
        });
    },
    increment_index(key) {
      return Number(key) + 1;
    },
  },
};
</script>
  
<style scoped>
span {
  font-size: 15px;
}

.border-bottom {
  border-bottom: 2px solid black !important;
}

.line-height {
  line-height: 10px;
}

.br {
  border: black solid 2px;
}

.table {
  border: 2px solid black;
}

::v-deep .table {
  box-shadow: none !important;
}

.table tr td {
  border: 2px solid black !important;
  /* border: none !important; */
  /* margin: 4px; */
  font-size: 18px !important;
}

.table tr th {
  border: 2px solid black;
  font-size: 18px !important;
}

.headertable tr td {
  border: none !important;
  font-weight: bold;
}
</style>